<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :items="items"
    clearable
    item-color="grey darken-4"
    :menu-props="{ offsetY: true, overflowY: true }"
    item-value="value"
    item-text="text"
    :label="$t('time.overtime_request.period')"
  >
    <template slot="selection" slot-scope="data">
      {{ data.item.text }}
    </template>
    <template slot="item" slot-scope="data">
      {{ data.item.text }}
    </template>
  </v-select>
</template>

<script>
  export default {
    inheritAttrs: true,
    name: "overtime-period-picker",
    props: {
      value: String
    },
    data: vm => ({
      selected: null,
      items: [
        {
          text: vm.$t("time.overtime_request.periods.CLOCK_IN"),
          value: "CLOCK_IN"
        },
        {
          text: vm.$t("time.overtime_request.periods.CLOCK_OUT"),
          value: "CLOCK_OUT"
        },
        {
          text: vm.$t("time.overtime_request.periods.CLOCK_IN_OUT"),
          value: "CLOCK_IN_OUT"
        }
      ]
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    },
    methods: {}
  };
</script>

<style scoped></style>
